import React, { createContext, useContext, useEffect, useState } from 'react'

import { useApplicationContext } from './ApplicationContext'
import { useAuthContext } from './AuthContext'
import { useStore } from 'zustand'

import { useGetTranslations } from '@/i18n/index'

import { ApplicationStore, createApplicationStore } from '@/state/store'

import { ToastNotificationT } from '@/types/globalTypes'

type ApplicationStoreProviderProps = {
    children: React.ReactNode
    setToastNotification: React.Dispatch<React.SetStateAction<ToastNotificationT>>
}

export const ApplicationStoreContext = createContext<ReturnType<
    typeof createApplicationStore
> | null>(null)

const ApplicationStoreProvider: React.FC<ApplicationStoreProviderProps> = ({
    children,
    setToastNotification,
}) => {
    const translations = useGetTranslations()
    const { profile, user } = useAuthContext()
    const { applicationState } = useApplicationContext()
    const venueId = applicationState.venue.id

    const [store] = useState<ReturnType<typeof createApplicationStore> | null>(
        createApplicationStore({
            applicationState: {
                setToastNotification,
                translations,
                profile,
                user,
                venueId,
            },
        })
    )

    useEffect(() => {
        if (store) {
            store.setState((state: ApplicationStore) => {
                return {
                    ...state,
                    applicationState: {
                        ...state.applicationState,
                        translations: translations,
                        setToastNotification,
                        profile,
                        user,
                        venueId,
                    },
                }
            })
        }

    }, [profile, setToastNotification, store, translations, user, venueId])

    return (
        <ApplicationStoreContext.Provider value={store}>
            {children}
        </ApplicationStoreContext.Provider>
    )
}

export const useApplicationStore = <T,>(selector: (state: Partial<ApplicationStore>) => T): T => {
    const store = useContext(ApplicationStoreContext)
    if (!store) {
        throw new Error('Missing ApplicationStoreProvider')
    }
    return useStore(store, selector)
}

export default ApplicationStoreProvider
