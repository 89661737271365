
import { captureException } from '@sentry/nextjs'

import { PostgrestError } from '@supabase/supabase-js'

import supabaseBrowserClient from '@/lib/client'

import {
    DefaultTableT,
    ExtendedReservationT,
    ExtendedReservationUpdateT,
    ReservationInsertT,
    ReservationT,
    ReservationUpdateT,
    TableT,
} from '@/types/globalTypes'
import { formatDate } from '@/utils/calendarUtils'

const useBookingServiceApi = () => {
    const supabaseClient = supabaseBrowserClient

    const getTables = async (startDate: string, endDate: string, venue_id: string) => {
        const { data, error } = await supabaseClient
            .from('venue_tables')
            .select()
            .gte('table_date', startDate)
            .lte('table_date', endDate)
            .eq('venue_id', venue_id)

        if (error) {
            captureException(error)
        }

        return { tables: data, tableError: error }
    }

    const getSpecificTable = async (tableId: string) => {
        let { data, error } = await supabaseClient
            .from('venue_tables')
            .select('*')
            .eq('id', tableId)
            .single()

        if (error) {
            captureException(error)
        }

        return { data, error }
    }
    const getSpecificStaticTable = async (tableId: string, startDate: string, endDate: string) => {
        let { data, error } = await supabaseClient
            .from('venue_tables')
            .select('*')
            .eq('static_reservation_id', tableId)
            .gte('table_date', startDate)
            .lte('table_date', endDate)
            .limit(1)

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const copyDefaultTableLayout = async (date: string) => {
        try {
            // Retrieve the current session from the Supabase client
            const { data: { session } } = await supabaseClient.auth.getSession();


            const response = await fetch(`/api/copy-table-layout?date=${date}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to copy table layout');
            }

            return { data: data as DefaultTableT, error: null };
        } catch (error) {
            captureException(error);
            return { data: null, error };
        }
    };

    const generateQrCode = async (tableId: string, pageMode: string, venueId: string) => {
        const { data, error } = await supabaseClient.functions.invoke('generate-qr-code', {
            body: {
                tableId,
                pageMode,
                venueId
            },
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }
    const generateNewPinCode = async (static_reservation_id: string) => {
        const { data, error } = await supabaseClient.functions.invoke('generate-pin-code', {
            body: {
                static_reservation_id,
            },
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const createTable = async (tableData: Partial<TableT>) => {
        const { data, error } = await supabaseClient
            .from('venue_tables')
            .insert({ ...tableData, id: tableData.id, venue_id: tableData.venue_id })
            .select()
        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const updateTable = async (tableData: TableT) => {
        const { data, error } = await supabaseClient
            .from('venue_tables')
            .update({ ...tableData })
            .eq('id', tableData.id)
            .select()
        if (error) {
            captureException(error)
        }

        return [data, error]
    }
    const bulkUpdateTables = async (tableData: TableT[]) => {
        const { data, error } = await supabaseClient
            .from('venue_tables')
            .upsert([...tableData])
            .select()

        if (error) {
            captureException(error)
        }

        return { data, error }
    }
    const deleteTable = async (tableId: string) => {
        const { error } = await supabaseClient.from('venue_tables').delete().eq('id', tableId)
        if (error) {
            captureException(error)
        }

        return error
    }

    const getReservationById = async (id: string) => {
        const { data, error } = await supabaseClient
            .from('reservations')
            .select('*')
            .eq('id', id)
            .limit(1)
        if (error) {
            captureException(error)
        }

        return { data, error }
    }


    const getStaticReservations = async (venue_id: string) => {
        const { data, error } = await supabaseClient.from('reservations').select(
            `
            *,
            pin_codes:pin_codes(pin_code)
        `
        ).eq('venue_id', venue_id).eq('is_static', true)

        if (error) {
            captureException(error)
        }

        return {
            reservationData: data,
            error
        }
    }

    const getReservationByMultipleIds = async (ids: string[]) => {
        const { data, error } = await supabaseClient.from('reservations').select('*').in('id', ids)
        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const getReservationsByDateRange: (
        startDate: string,
        endDate: string
    ) => Promise<{
        reservationData: ReservationT[]
        reservationError: PostgrestError | null
    }> = async (startDate, endDate) => {
        let { data: dateRangeData, error: dateRangeError } = await supabaseClient
            .from('reservations')
            .select(
                `
                *,
                table:venue_tables!public_reservations_table_id_fkey(*),
                pin_codes:pin_codes(pin_code)
            `
            )
            .gte('date_of_reservation', startDate)
            .lte('date_of_reservation', endDate)
            .is('is_static', false)

        let { data: staticData, error: staticError } = await supabaseClient
            .from('reservations')
            .select(
                `
                *,
                pin_codes:pin_codes(pin_code)
            `
            )
            .is('is_static', true)

        if (dateRangeError || staticError) {
            captureException(dateRangeError || staticError)
            return { reservationData: null, reservationError: dateRangeError || staticError }
        }

        const combinedData = [...dateRangeData, ...staticData]

        // Map over the combined data to flatten the structure
        const flattenedData: (ReservationT & { pin_code: string })[] = combinedData.map(
            (reservation) => {
                const { pin_codes, table, ...rest } = reservation as ReservationT & { pin_codes: { pin_code: string }[], table: TableT }
                return {
                    ...rest,
                    table,
                    pin_code: pin_codes?.[0]?.pin_code,
                }
            }
        )

        return { reservationData: flattenedData, reservationError: null }
    }
    const getAvailableReservationSlots = async (startDate: string, endDate: string) => {
        class TimeRange {
            constructor(public start: Date, public end: Date) {
                if (this.start >= this.end) {
                    throw new Error("Start time must be before end time.");
                }
            }

            overlaps(other: TimeRange): boolean {
                return this.start < other.end && this.end > other.start;
            }

            intersection(other: TimeRange): TimeRange | null {
                if (!this.overlaps(other)) return null;
                return new TimeRange(
                    new Date(Math.max(this.start.getTime(), other.start.getTime())),
                    new Date(Math.min(this.end.getTime(), other.end.getTime()))
                );
            }

            contains(other: TimeRange): boolean {
                return this.start <= other.start && this.end >= other.end;
            }

            duration(): number {
                // Returns duration in milliseconds
                return this.end.getTime() - this.start.getTime();
            }

            isAdjacentTo(other: TimeRange): boolean {
                return this.end.getTime() === other.start.getTime() || this.start.getTime() === other.end.getTime();
            }

            equals(other: TimeRange): boolean {
                return this.start.getTime() === other.start.getTime() && this.end.getTime() === other.end.getTime();
            }

            split(overlap: TimeRange): TimeRange[] {
                if (!this.overlaps(overlap)) {
                    return [this]; // No split needed if there's no overlap
                }

                const parts: TimeRange[] = [];
                if (this.start < overlap.start) {
                    parts.push(new TimeRange(this.start, overlap.start));
                }
                if (overlap.end < this.end) {
                    parts.push(new TimeRange(overlap.end, this.end));
                }
                return parts;
            }
        }

        // Fetch reservations within the specified date range
        let { data: reservationData, error: reservationError } = await supabaseClient
            .from('reservations')
            .select('*')
            .gte('date_of_reservation', startDate)
            .lte('date_of_reservation', endDate)
            .is('is_static', false);

        if (reservationError) {
            throw new Error('Error fetching reservations');
        }

        const availableSlots: TimeRange[] = [];
        let previousEndTime = new Date(startDate); // Use startDate as the initial previousEndTime

        for (const reservation of reservationData) {
            const reservationStart = new Date(`${formatDate(new Date(reservation.date_of_reservation))}T${reservation.start_time}`);
            const reservationEnd = reservation.end_time ? new Date(`${formatDate(new Date(reservation.date_of_reservation))}T${reservation.end_time}`) : new Date(endDate);

            if (reservationStart > previousEndTime) {
                availableSlots.push(new TimeRange(previousEndTime, reservationStart));
            }
            previousEndTime = reservationEnd;
        }

        if (previousEndTime < new Date(endDate)) {
            availableSlots.push(new TimeRange(previousEndTime, new Date(endDate)));
        }
        console.log(availableSlots);

        return availableSlots;
    };

    const getPinCodeByReservationId = async (reservationId: string) => {
        let { data, error } = await supabaseClient
            .from('reservations')
            .select(
                `
                pin_codes:pin_codes(pin_code)
            `
            )
            .eq('id', reservationId)
        if (error) {
            captureException(error)
            return { data: null, error }
        }

        const pinCode = data?.[0]?.pin_codes?.[0]?.pin_code
        return { pinCode, error }
    }

    const createReservation = async (reservationData: ReservationInsertT) => {
        const { error, data } = await supabaseClient
            .from('reservations')
            .insert({ ...reservationData })
            .select('*')
        if (error) {
            captureException(error)
        }

        return [error, data]
    }
    const createReservationWithoutSelect = async (reservationData: ReservationT) => {
        const { error, data } = await supabaseClient
            .from('reservations')
            .insert({ ...reservationData })
        if (error) {
            captureException(error)
        }

        return [error, data]
    }

    const updateReservation = async (reservation: ExtendedReservationUpdateT) => {
        const { hasPendingOrder, pin_code, table, ...rest } = reservation

        const { data, error } = await supabaseClient
            .from('reservations')
            .update(rest as ReservationUpdateT)
            .eq('id', reservation.id)
            .select()
        if (error) {
            captureException(error)
        }

        return { data, error }
    }
    const bulkUpdateReservation = async (reservations: ReservationT[]): Promise<{ data: ReservationT[], error: PostgrestError | null }> => {

        try {

            const { data: { session } } = await supabaseClient.auth.getSession();

            const response = await fetch('/api/bulk-update-reservations', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`,
                },
                method: 'POST',
                body: JSON.stringify({ reservationsToUpdate: reservations }),
            })

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to update reservations');
            }

            return { data: data as ReservationT[], error: null };
        } catch (error) {
            captureException(error);
            return { data: null, error };
        }

    }



    const deleteReservation = async (reservation: ReservationT) => {
        const { error } = await supabaseClient
            .from('reservations')
            .delete()
            .eq('id', reservation.id)
        if (error) {
            captureException(error)
        }

        return error
    }

    const deleteMultipleReservations = async (idsToDelete: string[]) => {
        const { error } = await supabaseClient.from('reservations').delete().in('id', idsToDelete)
        if (error) {
            captureException(error)
        }

        return error
    }

    const getEventsByDateRange = async (startDate: string, endDate: string, locale: string, venueId: string) => {
        const { data, error } = await supabaseClient.rpc('get_events_by_date_range', {
            p_start_date: startDate,
            p_end_date: endDate,
            p_locale: locale,
            p_venue_id: venueId
        })
        if (error) {
            captureException(error)
            console.error('Error fetching events:', error)
            return { eventsData: null, eventError: error }
        }

        return { eventsData: data, eventError: error }
    }

    const verify_reservation_pin_code = async (reservationId: string, pinCode: string) => {
        /* We provide the pin code and if it's correct we create and return valid token which holds the reservation id and role of the customer */
        let { data, error } = await supabaseClient.rpc('verify_reservation_pin_code', {
            p_reservation_id: String(reservationId),
            p_pin_code: String(pinCode),
        })
        if (error) {
            captureException(error)
        }
        return [data, error]
    }

    const getReservationCountPerDay = async (start_date: string, end_date: string) => {
        const { data, error } = await supabaseClient.rpc('get_reservation_count_per_day', {
            p_start_date: start_date,
            p_end_date: end_date,
        })
        if (error) {
            captureException(error)
            return { data: null, error }
        }
        return { data, error }
    }

    const objectApi = {
        copyDefaultTableLayout,
        getTables,
        createTable,
        updateTable,
        bulkUpdateTables,
        deleteTable,
        getSpecificTable,
        getSpecificStaticTable,
        generateQrCode,
        generateNewPinCode,
        verify_reservation_pin_code,
        createReservation,
        createReservationWithoutSelect,
        updateReservation,
        deleteReservation,
        deleteMultipleReservations,
        bulkUpdateReservation,
        getAvailableReservationSlots,
        getReservationsByDateRange,
        getReservationById,
        getPinCodeByReservationId,
        getReservationByMultipleIds,
        getEventsByDateRange,
        getReservationCountPerDay,
        getStaticReservations,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps



    return objectApi
}

export default useBookingServiceApi
