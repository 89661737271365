/* eslint-disable react-hooks/rules-of-hooks */
import tablePageReducer from '../reducers/tablePageReducer';
import { Setter } from '@dhmk/zustand-lens';

import {
    ApplicationAction,
    ExtendedOrderT,
    ExtendedReservationT,
    ModifiedExtendedMenuItemT,
    TablePageState,
    ToastNotificationT,
} from '@/types/globalTypes';
import { CustomLens } from '../store';
import { ENUM_ACTION_TYPES, ENUM_MODES } from '@/enums/Enums';
import useTableServiceApi from '@/hooks/api/useTableServiceApi';
import useBookingServiceApi from '@/hooks/api/useBookingServiceApi';
import { mapSelectedItems } from '@/utils/functionUtils';
import { NOTIFICATION_TYPE, ORDER_STATUS_CONSTANTS, STATUS_CONSTANTS } from '@/constants/constants';
import { PostgrestError } from '@supabase/supabase-js';
import { getInitialOrderState, getInitialTablePageState, updateState } from '@/utils/stateUtils';

export type TTablePageSlice = {
    state: TablePageState;
    initialOrderState: ExtendedOrderT
    dispatch: (action: ApplicationAction) => void;
    setLoading: Setter<boolean>;
    setToastNotification: React.Dispatch<React.SetStateAction<ToastNotificationT>>;
    handleCreateOrder: (
        selectedMenuItems: ModifiedExtendedMenuItemT[],
        totalPrice: number,
        specialRequests: string
    ) => Promise<void>;
    handleUpdateOrder: (
        newOrder: ExtendedOrderT,
        initialOrderItemsState?: ModifiedExtendedMenuItemT[],
        updateOrderItems?: boolean
    ) => Promise<void>;
    handleConfirmAction: (actionType: string) => Promise<void>;
    handleCompleteReservation: () => Promise<void>;
    handleViewItemWithOptions: (item: ModifiedExtendedMenuItemT, mode?: ENUM_MODES) => void;
    handleCloseMenuItemsPopup: (open: boolean) => void;
    setSelectedMenuItems: Setter<ModifiedExtendedMenuItemT[]>;
    setSelectedItemMode: Setter<string>;
    setOrderMode: Setter<ENUM_MODES>;
    setSelectedOrderSingleItem: Setter<ModifiedExtendedMenuItemT>;
    setActionType: Setter<string>;
    setSelectedOrder: Setter<ExtendedOrderT>;
};



export const tablePageSlice: CustomLens<TTablePageSlice> = (set, get, api, ctx, setGlobalState, getGlobalState) => {
    const { createOrder, updateOrder, updateOrderAndOrderItems } = useTableServiceApi();
    const { updateReservation } = useBookingServiceApi();


    const createNestedSetter = <K extends keyof TablePageState>(key: K) => (value: TablePageState[K]) => set((state: TTablePageSlice) => ({
        ...state,
        state: {
            ...state.state,
            [key]: updateState(state.state[key], value),
        },
    }));

    const setLoading = (loading: boolean) => {
        set((state) => ({
            state: { ...state.state, loading }
        }));
    };

    const setToastNotification = (notification: ToastNotificationT) => getGlobalState().applicationState.setToastNotification(notification);

    const test = () => console.log(getGlobalState());


    const dispatch = (action: ApplicationAction) =>
        set((state) => ({
            ...state,
            state: tablePageReducer(state.state, action),
        }));

    const handleCreateOrder = async (
        selectedMenuItems: ModifiedExtendedMenuItemT[],
        totalPrice: number,
        specialRequests: string
    ) => {
        setLoading(true);

        const orderItems = mapSelectedItems(selectedMenuItems);
        const guestID: string = getGlobalState().applicationState.user?.app_metadata.guest_id || null;
        const venueId = getGlobalState().applicationState.venueId
        const reservation = get().state.reservation;

        const mappedData = {
            order_items: orderItems,
            total_price: totalPrice,
            status: ORDER_STATUS_CONSTANTS.PENDING,
            reservation_id: reservation.id,
            waiter: null,
            waiter_name: null,
            special_requests: specialRequests,
            guest_id: guestID,
            venue_id: venueId,
        } as ExtendedOrderT;

        const [data, error] = await createOrder(mappedData);

        if (error) {
            const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);
            return;
        }

        setLoading(false);
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    createOrderPopup: false,
                    openMenuPopup: false,
                },
            },
        }));
        set((state) => ({
            state: {
                ...state.state,
                selectedMenuItems: [],
            },
        }));
        dispatch({
            type: ENUM_ACTION_TYPES.ADD_ORDER,
            payload: { newOrder: data },
        });
    };

    const handleUpdateOrder = async (
        newOrder: ExtendedOrderT,
        initialOrderItemsState: ModifiedExtendedMenuItemT[] = null,
        updateOrderItems = false
    ) => {
        setLoading(true);
        let data: ExtendedOrderT, error: PostgrestError;
        const { buttons, ...restOfOrder } = newOrder;

        if (updateOrderItems) {
            let { orderData, orderError } = await updateOrderAndOrderItems(
                {
                    ...restOfOrder,
                    last_updated_by: getGlobalState().applicationState.profile.id,
                },
                initialOrderItemsState
            );
            data = orderData;
            error = orderError;
        } else {
            const { orderData, orderError } = await updateOrder(restOfOrder);
            data = orderData as ExtendedOrderT;
            error = orderError;
        }

        if (error) {
            const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);
            return;
        }

        setLoading(false);
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    selectedOrderPopup: false,
                    orderActionsPopup: false,
                    openCancelOrderPopup: false,
                },
            },
        }));
        set((state) => ({
            state: {
                ...state.state,
                selectedMenuItems: [],
                selectedItemMode: ENUM_MODES.CREATE,
                orderMode: ENUM_MODES.CREATE,
            },
        }));
        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_ORDER,
            payload: { updatedOrder: data },
        });
    };

    const handleConfirmAction = async (actionType: string) => {
        const reservation = get().state.reservation

        const { error } = await updateReservation({
            ...reservation,
            id: reservation.id,
            actions: actionType,
        });

        if (error) {
            const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_RESERVATION,
            payload: { updatedReservation: { ...reservation, actions: actionType } as ExtendedReservationT },
        });

        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openConfirmActionPopup: false,
                    openCancelActionPopup: false,
                    paymentActionsPopup: false,
                    openActionsPopup: false,
                },
            },
        }));
        set((state) => ({
            state: {
                ...state.state,
                actionType: actionType,
            },
        }));
    };

    const handleCompleteReservation = async () => {
        const reservation = get().state.reservation;
        const { data, error } = await updateReservation({
            ...reservation,
            id: reservation.id,
            status: STATUS_CONSTANTS.COMPLETED,
        });

        if (error) {
            const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_RESERVATION,
            payload: { updatedReservation: data[0] },
        });

        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openCompleteReservationPopup: false,
                },
            },
        }));
    };

    const handleViewItemWithOptions = (item: ModifiedExtendedMenuItemT, mode = ENUM_MODES.EDIT) => {
        set((state) => ({
            state: {
                ...state.state,
                selectedOrderSingleItem: item,
                selectedItemMode: mode,
            },
        }));
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    menuAddonsPopup: true,
                },
            },
        }));
    };

    const handleCloseMenuItemsPopup = (open: boolean) => {
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    menuAddonsPopup: open,
                },
            },
        }));
    };


    return {
        state: getInitialTablePageState(),
        initialOrderState: getInitialOrderState(),
        dispatch,
        setLoading,
        setToastNotification,
        handleCreateOrder,
        handleUpdateOrder,
        handleConfirmAction,
        handleCompleteReservation,
        handleViewItemWithOptions,
        handleCloseMenuItemsPopup,
        setSelectedMenuItems: createNestedSetter('selectedMenuItems'),
        setSelectedItemMode: createNestedSetter('selectedItemMode'),
        setOrderMode: createNestedSetter('orderMode'),
        setSelectedOrderSingleItem: createNestedSetter('selectedOrderSingleItem'),
        setActionType: createNestedSetter('actionType'),
        setSelectedOrder: createNestedSetter('selectedOrder'),
        test
    };
};
