import floorPlanReducer from './floorPlanReducer.ts'
import ordersReducer from './ordersReducer.ts'
import reservationsReducer from './reservationReducer.ts'
import tablesReducer from './tablesReducer.ts'

import { ACTION_TYPES, ORDER_STATUS_CONSTANTS } from '@/constants/constants.ts'

import { findTableReservationData, getDefaultExtendedReservationStructure, getDefaultTableStructure } from '@/utils/stateUtils.ts'

import {
    ApplicationAction,
    ExtendedReservationT,
    InitOrdersDashboardDataPayload,
    OrdersDashboardState,
} from '@/types/globalTypes.js'

const ordersDashboardReducer = (
    state: OrdersDashboardState,
    action: ApplicationAction
): OrdersDashboardState => {
    switch (action.type) {
        case ACTION_TYPES.INIT_ORDERS_DASHBOARD_DATA: {
            const payload = action.payload as InitOrdersDashboardDataPayload

            const { sections, floors, tables, reservationData, orders, selectedLanguage } = payload
            // Map order status to the reservation
            const mappedReservations = reservationData.map((reservation) => {
                const ordersForThisReservation = orders.filter(
                    (order) => order.reservation_id === reservation.id
                )
                const hasPendingOrder = ordersForThisReservation.some(
                    (order) => order.status === ORDER_STATUS_CONSTANTS.PENDING
                )

                const tableData = tables.find(
                    (table) => (table.static_reservation_id === reservation.id || table.id === reservation.table_id))


                if (tableData) {

                    return {
                        ...reservation,
                        hasPendingOrder,
                        table: tableData,
                    }
                }

                return {
                    ...reservation,
                    hasPendingOrder,
                    table: getDefaultTableStructure(),
                }


            }) as ExtendedReservationT[]

            // Map orders to reservations
            const mappedOrders = orders.map((order) => {
                const reservationIndex = mappedReservations.findIndex(
                    (reservation) => reservation.id === order.reservation_id
                )

                return reservationIndex !== -1
                    ? { ...order, reservation: mappedReservations[reservationIndex] }
                    : {
                        ...order,
                        reservation: getDefaultExtendedReservationStructure()
                    }
            })

            const mappedTables = tables.map((table) => {
                // Find the section and floor data for this table
                const tableSection = sections.find((section) => section.id === table.section_id)
                const tableFloor = floors.find((floor) => floor.id === tableSection?.floor_id)

                // Find the localized names for the section and floor
                const sectionLocalization = tableSection?.sections_localization.find(
                    (localization) =>
                        localization.language_code === selectedLanguage ||
                        localization.language_code === 'en'
                )
                const floorLocalization = tableFloor?.floors_localization.find(
                    (localization) =>
                        localization.language_code === selectedLanguage ||
                        localization.language_code === 'en'
                )

                const tableReservationData = findTableReservationData(mappedReservations, table)


                if (tableReservationData) {
                    return {
                        ...table,
                        reservation: tableReservationData,
                        section: sectionLocalization?.translated_name || '',
                        floor: floorLocalization?.translated_name || '',
                    }
                } else {
                    return {
                        ...table,
                        reservation: getDefaultExtendedReservationStructure(),
                        section: sectionLocalization?.translated_name || '',
                        floor: floorLocalization?.translated_name || '',
                    }
                }
            })

            return {
                ...state,
                floors,
                sections,
                tablesData: mappedTables,
                reservations: mappedReservations,
                orders: mappedOrders,
            }
        }

        case ACTION_TYPES.INSERT_RESERVATION:
        case ACTION_TYPES.UPDATE_RESERVATION:
        case ACTION_TYPES.DELETE_RESERVATION:
            return {
                ...state,
                ...reservationsReducer(state, action),
            }

        case ACTION_TYPES.INSERT_ORDER:
        case ACTION_TYPES.UPDATE_ORDER:
        case ACTION_TYPES.DELETE_ORDER:
        case ACTION_TYPES.ASSIGN_TABLE_TO_WAITER:
            return {
                ...state,
                ...ordersReducer(state, action),
            }
        case ACTION_TYPES.INSERT_TABLE:
        case ACTION_TYPES.UPDATE_TABLE:
        case ACTION_TYPES.DELETE_TABLE:
            return {
                ...state,
                ...tablesReducer(state, action),
            }
        case ACTION_TYPES.INSERT_FLOOR:
        case ACTION_TYPES.UPDATE_FLOOR:
        case ACTION_TYPES.DELETE_FLOOR:
        case ACTION_TYPES.INSERT_SECTION:
        case ACTION_TYPES.UPDATE_SECTION:
        case ACTION_TYPES.DELETE_SECTION:
            return {
                ...state,
                ...floorPlanReducer(state, action),
            }
        default:
            return state
    }
}

export default ordersDashboardReducer
