import { ENUM_ACTION_TYPES } from '@/enums/Enums.ts'

import {
    ApplicationAction,
    ExtendedTableT,
    TableState,
} from '@/types/globalTypes'
import { findTableReservationData, getDefaultExtendedReservationStructure, getDefaultTableStructure } from '@/utils/stateUtils'

const tablesReducer = (prevState: TableState, action: ApplicationAction): TableState => {
    switch (action.type) {
        case ENUM_ACTION_TYPES.INSERT_TABLE: {
            const { newTable } = action.payload
            const tableIndex = prevState.tablesData.findIndex((table) => table.id === newTable.id)

            // If the table is found return prevState to prevent duplicate
            if (tableIndex !== -1) {
                return prevState
            }
            // If the table is not found map check if with a tied reservation\

            if (tableIndex == -1) {
                const filteredReservation = prevState.reservations.find(
                    (reservation) =>
                        (reservation.table !== null && reservation.table.id === newTable.id) ||
                        newTable.static_reservation_id === reservation.id
                )

                const updatedTableData = filteredReservation
                    ? { ...newTable, reservation: filteredReservation }
                    : {
                        ...newTable,
                        reservation: getDefaultExtendedReservationStructure()
                    }

                // Create a new array with the updated table
                const updatedTables = [...prevState.tablesData, updatedTableData as ExtendedTableT]

                return { ...prevState, tablesData: updatedTables }
            }

            return prevState
        }

        case ENUM_ACTION_TYPES.UPDATE_TABLE: {
            const { updatedTable } = action.payload

            // Find the index of the table using the table's id
            const tableIndex = prevState.tablesData.findIndex(
                (table) => table.id === updatedTable.id
            )



            // If the table is found, update it with the new data
            if (tableIndex !== -1) {


                const tableReservationData = findTableReservationData(prevState.reservations, updatedTable)


                const updatedTableData = tableReservationData
                    ? { ...updatedTable, reservation: tableReservationData }
                    : {
                        ...updatedTable,
                        reservation: getDefaultExtendedReservationStructure()
                    }

                // Create a new array with the updated table
                const updatedTables = [...prevState.tablesData]
                updatedTables[tableIndex] = updatedTableData as ExtendedTableT

                return { ...prevState, tablesData: updatedTables }
            }

            return prevState
        }

        case ENUM_ACTION_TYPES.UPDATE_TABLES: {
            const { updatedTables } = action.payload

            // Create a new array with the updated tables
            const newTables = prevState.tablesData.map((table) => {
                const updatedTable = updatedTables.find((t) => t.id === table.id)

                if (updatedTable) {

                    const tableReservationData = findTableReservationData(prevState.reservations, updatedTable)


                    const updatedTableData = tableReservationData
                        ? { ...updatedTable, reservation: tableReservationData }
                        : {
                            ...updatedTable,
                            reservation: getDefaultExtendedReservationStructure()
                        }

                    return updatedTableData as ExtendedTableT
                }

                return table
            })

            return { ...prevState, tablesData: newTables }
        }

        case ENUM_ACTION_TYPES.DELETE_TABLE: {
            const { deletedTableId } = action.payload

            // Remove the table from the tables array
            const updatedTables = prevState.tablesData.filter(
                (table) => table.id !== deletedTableId
            )

            // Update reservations that were associated with the deleted table
            const updatedReservations = prevState.reservations.map((reservation) => {
                if (reservation.table !== null && reservation.table.id === deletedTableId) {
                    return {
                        ...reservation,
                        table: getDefaultTableStructure()
                    }
                }
                return reservation
            })

            return { ...prevState, tablesData: updatedTables, reservations: updatedReservations }
        }

        case ENUM_ACTION_TYPES.COPY_DEFAULT_TABLE_LAYOUT: {
            const { newTables } = action.payload

            const updatedTables = [...prevState.tablesData]

            newTables.forEach((tableData) => {
                const tableExists = updatedTables.some((table) => table.id === tableData.id)

                if (!tableExists) {
                    updatedTables.push({
                        ...tableData,
                        floor: '',
                        section: '',
                        reservation: getDefaultExtendedReservationStructure()
                    })
                }
            })

            return { ...prevState, tablesData: updatedTables }
        }

        // other cases...

        default:
            return prevState
    }
}

export default tablesReducer
