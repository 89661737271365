import floorPlanReducer from './floorPlanReducer.ts'
import reservationsReducer from './reservationReducer.ts'
import tablesReducer from './tablesReducer.ts'

import { ACTION_TYPES } from '@/constants/constants.ts'

import { getDefaultExtendedReservationStructure, getDefaultTableStructure, isTable } from '@/utils/stateUtils.ts'

import {
    ApplicationAction,
    ExtendedReservationT,
    InitReservationsDashboardDataPayload,
    ReservationsDashboardState,
} from '@/types/globalTypes.js'

const reservationsDashboardReducer = (
    state: ReservationsDashboardState,
    action: ApplicationAction
): ReservationsDashboardState => {
    switch (action.type) {
        case ACTION_TYPES.INIT_RESERVATIONS_DASHBOARD_DATA: {
            const payload = action.payload as InitReservationsDashboardDataPayload
            const { sections, floors, tables, reservationData, selectedLanguage } = payload

            const mappedReservations = reservationData.map((reservation) => {

                const tableData = tables.find(
                    (table) => (table.static_reservation_id === reservation.id || table.id === reservation.table_id)
                )

                if (tableData) {
                    return {
                        ...reservation,
                        table: tableData,
                    }
                }

                return {
                    ...reservation,
                    table: getDefaultTableStructure(),
                }


            }) as ExtendedReservationT[]

            const mappedTables = tables.map((table) => {
                // Find the section and floor data for this table
                const tableSection = sections.find((section) => section.id === table.section_id)


                const tableFloor = floors.find((floor) => floor.id === tableSection?.floor_id)

                // Find the localized names for the section and floor
                const sectionLocalization = tableSection?.sections_localization.find(
                    (localization) =>
                        localization.language_code === selectedLanguage ||
                        localization.language_code === 'en'
                )
                const floorLocalization = tableFloor?.floors_localization.find(
                    (localization) =>
                        localization.language_code === selectedLanguage ||
                        localization.language_code === 'en'
                )

                const tableReservationData = mappedReservations.find((reservation) => {
                    // Explicit check for 'table' being null or a string (possibly an ID comparison)
                    if (isTable(reservation.table) && reservation.is_active) {

                        const tableObj = reservation.table
                        return tableObj.id === table.id

                    } else if (reservation.table_id === table.id && reservation.is_active) {

                        return true

                    } else if (reservation.table_id === table.id && reservation.is_active === false && table.is_static === false) {
                            /* If no active reservations are set on the non static table we place the non active reservation */
                        if (mappedReservations.some((res) => res.table_id === table.id && res.is_active === true)) {
                            return
                        }
                        return true
                    }

                    // Fallback or additional logic if needed
                    return false
                })


                if (tableReservationData) {
                    return {
                        ...table,
                        reservation: tableReservationData,
                        section: sectionLocalization?.translated_name || '',
                        floor: floorLocalization?.translated_name || '',
                    }
                } else {
                    return {
                        ...table,
                        reservation: getDefaultExtendedReservationStructure(),
                        section: sectionLocalization?.translated_name || '',
                        floor: floorLocalization?.translated_name || '',
                    }
                }
            })

            return {
                ...state,
                floors,
                sections,
                tablesData: mappedTables,
                reservations: mappedReservations,
            }
        }

        case ACTION_TYPES.INSERT_RESERVATION:
        case ACTION_TYPES.UPDATE_RESERVATION:
        case ACTION_TYPES.DELETE_RESERVATION:
            return {
                ...state,
                ...reservationsReducer(state, action),
            }

        case ACTION_TYPES.INSERT_TABLE:
        case ACTION_TYPES.UPDATE_TABLE:
        case ACTION_TYPES.DELETE_TABLE:
            return {
                ...state,
                ...tablesReducer(state, action),
            }
        case ACTION_TYPES.INSERT_FLOOR:
        case ACTION_TYPES.UPDATE_FLOOR:
        case ACTION_TYPES.DELETE_FLOOR:
        case ACTION_TYPES.INSERT_SECTION:
        case ACTION_TYPES.UPDATE_SECTION:
        case ACTION_TYPES.DELETE_SECTION:
            return {
                ...state,
                ...floorPlanReducer(state, action),
            }
        default:
            return state
    }
}

export default reservationsDashboardReducer
