/* eslint-disable react-hooks/rules-of-hooks */
import { CustomLens } from '../store';
import { Setter } from '@dhmk/zustand-lens';
import { ExtendedFloorLocalizationT, ExtendedReservationT, ExtendedSectionLocalizationT, ExtendedTableT, FloorLocalizationT, LocalizationT, ReservationT, SectionT, TableT, TabT, TCreateOrEditTableSubmitValues, TMappedSectionLocalization, ToastNotificationT } from '@/types/globalTypes';
import useAdminPanelServiceApi from '@/hooks/api/useAdminPanelServiceApi';
import useBookingServiceApi from '@/hooks/api/useBookingServiceApi';
import { NOTIFICATION_TYPE, PAGE_MODES } from '@/constants/constants';
import {
    getLocalizedTableData,
    updateFloorsState,
    updateSectionsState,
} from '@/utils/functionUtils.ts'
import { getDefaultExtendedReservationStructure, getDefaultTableStructure } from '@/utils/stateUtils';
import { ENUM_PAGE_MODES } from '@/enums/Enums';
import { PostgrestError } from '@supabase/supabase-js';
import { isTable } from 'drizzle-orm';

export type DefaultLayoutPageState = {
    loading: boolean;
    floorPlanLoading: boolean;
    floors: ExtendedFloorLocalizationT[];
    sections: ExtendedSectionLocalizationT[];
    tablesData: ExtendedTableT[];
    reservations: ExtendedReservationT[]
    selectedReservation: ExtendedReservationT
    selectedTable: ExtendedTableT;
    selectedPageTab: TabT;
    openFloorIds: { [key: string]: boolean }
    selectedFloor: ExtendedFloorLocalizationT
    selectedSection: ExtendedSectionLocalizationT
    pageMode: ENUM_PAGE_MODES
    setLoading: Setter<boolean>
    setFloors: Setter<ExtendedFloorLocalizationT[]>
    setSections: Setter<ExtendedSectionLocalizationT[]>
    setTablesData: Setter<TableT[]>
    setSelectedTable: Setter<ExtendedTableT>
    setSelectedReservation: Setter<ExtendedReservationT>
    setSelectedPageTab: Setter<TabT>
    setPageMode: Setter<ENUM_PAGE_MODES>
    fetchInitialData: (selectedLanguage: string) => Promise<void>
    handleCreateTable: (values: TCreateOrEditTableSubmitValues, selectedLanguage: string) => Promise<void>
    handleEditTable: (tableData: Partial<TableT>) => Promise<void>
    handleDeleteTable: (tableId: string) => Promise<void>
    handleGenerateNewPinCode: (static_reservation_id: string) => Promise<void>
    setOpenFloorIds: Setter<{ [key: string]: boolean }>
    setSelectedFloor: Setter<ExtendedFloorLocalizationT>
    setSelectedSection: Setter<ExtendedSectionLocalizationT>
    handleToggleFloor: (floorId: string) => void
    handleCreateOrEditFloor: (values: {
        id: string
        venue_id: string
        floors_localization: FloorLocalizationT
    }) => Promise<void>
    handleDeleteFloor: (floorId: string) => Promise<void>
    handleCreateOrEditSection: (values: TMappedSectionLocalization) => Promise<void>
    handleDeleteSection: (sectionId: string) => Promise<void>
    setSectionClassnames: (section: { id: string; name: string }) => string
    handleBulkUpdateSectionsAndTables: (sections: SectionT[], tables: TableT[], selectedLanguage: string) => Promise<PostgrestError | null>;
    setTableClassNames: (table: TableT) => string
};

export const defaultLayoutPageSlice: CustomLens<DefaultLayoutPageState> = (set, get, api, ctx, setGlobal, getGlobal) => {

    const {
        createDefaultTable,
        deleteDefaultTable,
        getDefaultTablesLayout,
        getFloorsLayout,
        createOrUpdateFloor,
        getSectionsLayout,
        updateDefaultTable,
        deleteFloor,
        deleteSection,
        createOrUpdateSection,
        bulkUpdateSections,
        bulkUpdateDefaultTable
    } = useAdminPanelServiceApi();

    const { generateNewPinCode, getStaticReservations } = useBookingServiceApi();

    const createSetter = <K extends keyof DefaultLayoutPageState>(key: K) => (value: DefaultLayoutPageState[K]) => set((state: DefaultLayoutPageState) => ({
        ...state,
        [key]: value,
    }));

    const setToastNotification = (notification: ToastNotificationT) => getGlobal().applicationState.setToastNotification(notification);

    const setTableClassNames = (table: TableT) => {
        let classNames = ''

        classNames += 'dark:!bg-slate-700'

        if (table.id === get().selectedTable.id) {
            // Check if the table is selected
            classNames += 'dark:!bg-opacity-80 dark:!bg-slate-600'
        }

        return classNames
    }

    const fetchInitialData = async (selectedLanguage: string) => {
        const venueId = getGlobal().applicationState.venueId;

        const { TOAST_NOTIFICATIONS_TEXT } = getGlobal().applicationState.translations || {};

        set({ loading: true });

        const { floors, floorsError } = await getFloorsLayout(venueId);
        const { sections, sectionsError } = await getSectionsLayout(venueId);
        const { data: tablesData, error } = await getDefaultTablesLayout(venueId);

        const { reservationData, error: reservationError } = await getStaticReservations(venueId)

        if (error || floorsError || sectionsError || reservationError) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            });
            set({ loading: false });
            return;
        }

        const mappedReservations = reservationData.map((reservation) => {

            const tableData = tablesData.find(
                (table) => (table.static_reservation_id === reservation.id || table.id === reservation.table_id)
            )

            if (tableData) {
                return {
                    ...reservation,
                    pin_code: reservation.pin_codes[0].pin_code,
                    table: tableData,
                }
            }

            return {
                ...reservation,
                table: getDefaultTableStructure(),
            }


        }) as ExtendedReservationT[]

        const mappedTables = tablesData.map((table) => {
            // Find the section and floor data for this table
            const tableSection = sections.find((section) => section.id === table.section_id)

            const mappedTable = getLocalizedTableData(table, sections, floors, selectedLanguage)


            const tableReservationData = mappedReservations.find((reservation) => {
                // Explicit check for 'table' being null or a string (possibly an ID comparison)
                if (isTable(reservation.table) && reservation.is_active) {

                    const tableObj = reservation.table
                    return tableObj.id === table.id

                } else if (reservation.table_id === table.id && reservation.is_active) {

                    return true

                } else if (reservation.table_id === table.id && reservation.is_active === false && table.is_static === false) {
                    /* If no active reservations are set on the non static table we place the non active reservation */
                    if (mappedReservations.some((res) => res.table_id === table.id && res.is_active === true)) {
                        return
                    }
                    return true
                }

                // Fallback or additional logic if needed
                return false
            })


            if (tableReservationData) {
                return {
                    ...table,
                    ...mappedTable,
                    reservation: tableReservationData,
                }
            } else {
                return {
                    ...table,
                    ...mappedTable,
                    reservation: getDefaultExtendedReservationStructure(),
                }
            }
        })


        set({ floors, sections, tablesData: mappedTables, reservations: mappedReservations, loading: false });
    };

    const handleCreateTable = async (values: TCreateOrEditTableSubmitValues, selectedLanguage: string) => {
        const venueId = getGlobal().applicationState.venueId;
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobal().applicationState.translations || {};

        const tableData = {
            table_name: values.table_name,
            reserved: values.reserved,
            section_id: values.section_id,
            waiter: null,
            is_static: values.is_static,
            venue_id: venueId,
        } as TableT;



        const { data, error } = await createDefaultTable(tableData);

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            });
        } else {

            const mappedTable = getLocalizedTableData(data.table, get().sections, get().floors, selectedLanguage)
            const mappedReservation = { ...data.reservation, table: mappedTable }

            set((state) => ({
                ...state,
                tablesData: [...state.tablesData, { ...mappedTable, reservation: mappedReservation }],
                reservations: [...state.reservations, mappedReservation],
            }));

            getGlobal().modalState.setOpenCreateTablePopup(false)
        }
    };

    const handleEditTable = async (tableData: TableT) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobal().applicationState.translations || {};

        const { data, error } = await updateDefaultTable(tableData);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            });
        } else {
            set((state) => {
                const newState = structuredClone(state.tablesData);
                const reservations = structuredClone(state.reservations);
                const tableIndex = newState.findIndex((table) => table.id === data.id);

                const reservationIndex = reservations.findIndex((reservation) => reservation.table_id === data.id);

                const updatedReservation = reservations[reservationIndex];
                let updatedTable = newState[tableIndex];

                if (updatedReservation) {
                    updatedTable = { ...newState[tableIndex], ...data, reservation: updatedReservation };
                } else {
                    updatedTable = { ...newState[tableIndex], ...data, reservation: getDefaultExtendedReservationStructure() };
                }

                newState[tableIndex] = updatedTable;

                return {
                    ...state,
                    tablesData: newState,
                    selectedTable: getDefaultTableStructure()
                };
            });

            getGlobal().modalState.setOpenEditTablePopup(false)
        }
    };

    const handleDeleteTable = async (tableId: string) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobal().applicationState.translations || {};

        const error = await deleteDefaultTable(tableId);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            });
        } else {
            set((state) => ({
                tablesData: state.tablesData.filter((table) => table.id !== tableId),
                selectedTable: getDefaultTableStructure()
            }));

            getGlobal().modalState.setOpenDeleteTablePopup(false)
        }
    };


    const handleGenerateNewPinCode = async (static_reservation_id: string) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobal().applicationState.translations || {};

        const [data, error] = await generateNewPinCode(static_reservation_id);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            });
        } else {
            set((state) => ({
                ...state,
                reservations: state.reservations.map((reservation) => {
                    if (reservation.id === static_reservation_id) {
                        return {
                            ...reservation,
                            pin_code: data.new_pin_code,
                            guest_id: data.new_guest_id,
                        };
                    }
                    return reservation;
                }),
                selectedTable: {
                    ...state.selectedTable,
                    reservation: {
                        ...state.selectedTable.reservation,
                        id: state.selectedTable.static_reservation_id,
                        pin_code: data.new_pin_code,
                        guest_id: data.new_guest_id,
                    },
                },
            }));

            getGlobal().modalState.setOpenGenerateNewPinCode(false)
            getGlobal().modalState.setOpenCreateTablePopup(false)

            setToastNotification({
                type: NOTIFICATION_TYPE.SUCCESS,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_SUCCESS_MESSAGE,
            });
        }
    };

    const handleToggleFloor = (floorId: string) => {
        set((state) => ({
            openFloorIds: {
                ...state.openFloorIds,
                [floorId]: !state.openFloorIds[floorId],
            },
            selectedSection: {
                id: '',
                floor_id: '',
                styles: {},
                x_coordinate: 0,
                y_coordinate: 0,
                sections_localization: [],
            },
        }))
    }

    const handleCreateOrEditFloor = async (values: {
        id: string
        venue_id: string
        floors_localization: FloorLocalizationT
    }) => {
        set({ floorPlanLoading: true })
        const { localizationData, localizationError } = await createOrUpdateFloor(values)

        if (localizationError) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: getGlobal().applicationState.translations.TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            set({ floorPlanLoading: false })
            return
        }

        set((state) => ({
            floors: updateFloorsState(state.floors, localizationData),
            floorPlanLoading: false,
        }))

        getGlobal().modalState.setOpenEditFloorPopup(false)
        getGlobal().modalState.setOpenCreateFloorPopup(false)


    }

    const handleDeleteFloor = async (floorId: string) => {
        set({ floorPlanLoading: true })
        const error = await deleteFloor(floorId)

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: getGlobal().applicationState.translations.TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            set({ loading: false })
            return
        }
        set((state) => ({
            floors: state.floors.filter((floor) => floor.id !== floorId),
            floorPlanLoading: false,
        }))
        getGlobal().modalState.setOpenDeleteFloorPopup(false)

    }

    const handleCreateOrEditSection = async (values: TMappedSectionLocalization) => {
        set({ floorPlanLoading: true })
        const { localizationData, sectionError } = await createOrUpdateSection(values)
        if (sectionError) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: getGlobal().applicationState.translations.TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            set({ floorPlanLoading: false })
            return
        }

        set((state) => ({
            sections: updateSectionsState(state.sections, localizationData),
            floorPlanLoading: false,
        }))

        getGlobal().modalState.setOpenCreateSectionPopup(false)
        getGlobal().modalState.setOpenEditSectionPopup(false)
    }



    const handleDeleteSection = async (sectionId: string) => {
        set({ floorPlanLoading: true })
        const error = await deleteSection(sectionId)

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: getGlobal().applicationState.translations.TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            set({ floorPlanLoading: false })
            return
        }
        set((state) => ({
            sections: state.sections.filter((section) => section.id !== sectionId),
            floorPlanLoading: false,
        }))


        getGlobal().modalState.setOpenDeleteSectionPopup(false)
    }

    const setSectionClassnames = (section: { id: string; name: string }) => {
        let classNames = ''

        if (section.id === get().selectedSection.id) {
            // Check if the table is selected
            classNames += ' !bg-opacity-20 !bg-slate-300'
        }

        return classNames
    }

    const handleBulkUpdateSectionsAndTables = async (sections: SectionT[], tables: TableT[], selectedLanguage: string): Promise<PostgrestError | null> => {
        set({ floorPlanLoading: true });

        const { sectionResponse, sectionError } = await bulkUpdateSections(sections);

        if (sectionError) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: getGlobal().applicationState.translations.TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            });
            set({ floorPlanLoading: false });
            return sectionError;
        }

        let updatedSections = get().sections.map((section) => {
            const updatedSection = sectionResponse.find((updatedSection) => updatedSection.id === section.id);
            return updatedSection || section;
        });

        const { data, error } = await bulkUpdateDefaultTable(tables);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: getGlobal().applicationState.translations.TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            });
            set({ floorPlanLoading: false });
            return error;
        }

        let updatedTables = get().tablesData.map((table) => {
            const updatedTable = data.find((updatedTable) => updatedTable.id === table.id);
            let mappedTable = getLocalizedTableData(updatedTable || table, get().sections, get().floors, selectedLanguage)
            return mappedTable;
        });

        set({ floors: get().floors, sections: updatedSections, tablesData: updatedTables, floorPlanLoading: false });

        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: getGlobal().applicationState.translations.TOAST_NOTIFICATIONS_TEXT.GENERIC_SUCCESS_MESSAGE,
        });
        set({ floorPlanLoading: false });

        return null;
    };

    return {
        loading: true,
        floorPlanLoading: false,
        floors: [],
        sections: [],
        tablesData: [],
        reservations: [],
        selectedReservation: getDefaultExtendedReservationStructure(),
        selectedTable: getDefaultTableStructure(),
        selectedPageTab: { id: 0, label: '' },
        pageMode: ENUM_PAGE_MODES.ADMIN,
        openFloorIds: {},
        selectedFloor: {
            id: '',
            floors_localization: [],
        },
        selectedSection: {
            id: '',
            floor_id: '',
            sections_localization: [],
            styles: {},
            x_coordinate: 0,
            y_coordinate: 0,
        },
        setLoading: createSetter('loading'),
        setFloors: createSetter('floors'),
        setSections: createSetter('sections'),
        setTablesData: createSetter('tablesData'),
        setSelectedTable: createSetter('selectedTable'),
        setSelectedPageTab: createSetter('selectedPageTab'),
        setOpenFloorIds: createSetter('openFloorIds'),
        setSectionClassnames,
        setSelectedFloor: createSetter('selectedFloor'),
        setSelectedReservation: createSetter('selectedReservation'),
        setSelectedSection: createSetter('selectedSection'),
        setPageMode: createSetter('pageMode'),
        setTableClassNames,
        handleToggleFloor,
        handleCreateTable,
        handleEditTable,
        handleDeleteTable,
        handleGenerateNewPinCode,
        handleCreateOrEditFloor,
        handleDeleteFloor,
        handleCreateOrEditSection,
        handleDeleteSection,
        handleBulkUpdateSectionsAndTables,
        fetchInitialData,
    };
};
