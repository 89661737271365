import { captureException } from '@sentry/nextjs'

import supabaseBrowserClient from '@/lib/client'

import { MODE_CONSTANTS } from '@/constants/constants.ts'

import { ENUM_MODES } from '@/enums/Enums'

import { flattenMenuItemData, getNewImageUrl } from '@/utils/functionUtils'

import {
    ExtendedCategoryT,
    ExtendedMenusT,
    ExtendedVenueSettingsT,
    LocalizationT,
    MappedWorkingHoursT,
    MenuCategoryT,
    MenuItemNutritionT,
    MenuItemsGroupCategoriesT,
    ModifiedExtendedCategoryT,
    ModifiedExtendedMenuItemT,
    ModifiedExtendedOptionalItemT,
    ModifiedMappedExtendedEventsT,
    ModifiedMappedGroupTWithMappedLocale,
    ReservationT,
    SectionLocalizationT,
    SectionT,
    TCreateUserEditUserFormValues,
    TMappedLocalization,
    TMappedSectionLocalization,
    TableT,
} from '@/types/globalTypes'
import { Json } from '@/types/supabase.types'

const useAdminPanelServiceApi = () => {
    const supabaseClient = supabaseBrowserClient


    const getUserProfiles = async (roles: string[], pageIndex = 0, pageSize = 20) => {
        const offset = pageIndex * pageSize

        let {
            data: profiles,
            error,
            count,
        } = await supabaseClient
            .from('profiles')
            .select('*')
            .overlaps('roles', [roles])
            .range(offset, offset + pageSize - 1)

        if (error) {
            captureException(error)
        }

        return { profiles, error, count }
    }

    const deleteUserProfile = async (userId: string) => {
        const { data, error } = await supabaseClient.functions.invoke('admin-delete-user', {
            body: {
                userIdToDelete: userId,
            },
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const createUserProfile = async (user: TCreateUserEditUserFormValues) => {
        const { data, error } = await supabaseClient.functions.invoke('admin-create-user', {
            body: {
                user,
            },
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }
    const updateUserProfile = async (user: TCreateUserEditUserFormValues) => {
        const { data, error } = await supabaseClient.functions.invoke('admin-update-user', {
            body: {
                user,
            },
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }
    const getDefaultTablesLayout = async (venue_id: string) => {
        const { data, error } = await supabaseClient.from('default_tables_layout').select('*').eq('venue_id', venue_id)

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const deleteDefaultTable = async (tableId: string) => {
        const { error } = await supabaseClient
            .from('default_tables_layout')
            .delete()
            .eq('id', tableId)

        if (error) {
            captureException(error)
        }

        return error
    }
    const updateDefaultTable = async (tableData: TableT) => {
        const { data, error } = await supabaseClient
            .from('default_tables_layout')
            .update({ ...tableData })
            .eq('id', tableData.id)
            .select()

        if (error) {
            captureException(error)
        }

        return { data: data[0], error }
    }

    const bulkUpdateDefaultTable = async (tableData: TableT[]) => {
        const { data, error } = await supabaseClient
            .from('default_tables_layout')
            .upsert([...tableData])
            .select()

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const createDefaultTable = async (tableData: TableT) => {

        const { data: { session } } = await supabaseClient.auth.getSession();

        const response = await fetch('/api/create-table', {
            body: JSON.stringify({ tableData }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.access_token}`,
            },
            method: 'POST',
        })

        const { data, error } = await response.json()

        if (!response.ok) {
            captureException(data.error)
        }

        return { data, error } as { data: { table: TableT, reservation: ReservationT & { pin_code: string }, }, error: Error }
    }

    const getFloorsLayout = async (venue_id: string) => {
        const { data: floors, error: floorsError } = await supabaseClient.from('floors').select(`id,
        floors_localization (
            *
          )
        `)
            .eq('venue_id', venue_id)

        if (floorsError) {
            captureException(floorsError)
        }

        return { floors, floorsError }
    }
    const getSectionsLayout = async (venue_id: string) => {
        const { data: sections, error: sectionsError } = await supabaseClient.from('sections')
            .select(`id,
                venue_id,
            floor_id,
            styles,
            x_coordinate,
            y_coordinate,
        sections_localization(*)`)
            .eq('venue_id', venue_id)

        if (sectionsError) {
            captureException(sectionsError)
        }

        return { sections, sectionsError }
    }

    const deleteFloor = async (floorId: string) => {
        const { error } = await supabaseClient.from('floors').delete().eq('id', floorId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const createOrUpdateFloor = async (data: {
        id: string
        floors_localization: { [langCode: string]: string },
        venue_id: string
    }) => {
        // Upsert floor
        let { data: floorData, error: floorError } = await supabaseClient
            .from('floors')
            .upsert([{ id: data.id, venue_id: data.venue_id }])
            .select()

        if (floorError) {
            captureException(floorError)
            return
        }

        const floorId = floorData[0].id || data.id

        // Prepare the translations for upsert
        const translationsToUpsert = Object.entries(data.floors_localization).map(
            ([langCode, translatedName]) => ({
                floor_id: floorId,
                language_code: langCode,
                translated_name: translatedName,

            })
        )

        // Upsert floor_localization
        const { data: localizationData, error: localizationError } = await supabaseClient
            .from('floors_localization')
            .upsert(translationsToUpsert, { onConflict: 'floor_id, language_code' })
            .select()

        if (localizationError) {
            captureException(localizationError)
            return { localizationData, localizationError }
        }

        return { localizationData, localizationError }
    }

    const createOrUpdateSection = async (sectionData: TMappedSectionLocalization) => {

        // Upsert section
        let { data: sectionResponse, error: sectionError } = await supabaseClient
            .from('sections')
            .upsert([{
                id: sectionData.id,
                floor_id: sectionData.floor_id,
                venue_id: sectionData.venue_id,
                styles: sectionData.styles,
                x_coordinate: sectionData.x_coordinate,
                y_coordinate: sectionData.y_coordinate
            }])
            .select()

        if (sectionError) {
            captureException(sectionError)
            console.error('Error upserting section:', sectionError)
            return
        }

        const sectionId = sectionResponse[0].id || sectionData.id

        // Prepare the translations for upsert
        const translationsToUpsert = Object.entries(sectionData.sections_localization).map(
            ([langCode, translatedName]) => ({
                section_id: sectionId,
                language_code: langCode,
                translated_name: translatedName,
                floor_id: sectionData.floor_id,
            }) as SectionLocalizationT
        )


        // Upsert sections_localization
        const { data: localizationData, error: localizationError } = await supabaseClient
            .from('sections_localization')
            .upsert(translationsToUpsert, { onConflict: 'section_id,language_code' })
            .select()

        if (localizationError) {
            captureException(localizationError)
            return { localizationData, localizationError }
        }

        // Add the floor_id to the localization data
        const mappedLocalizationData = translationsToUpsert.map((item) => ({
            ...item,
            floor_id: sectionData.floor_id,
        }))

        return { localizationData: mappedLocalizationData, sectionError }
    }

    const updateSection = async (sectionData: TMappedSectionLocalization) => {

        // Upsert section
        let { data: sectionResponse, error: sectionError } = await supabaseClient
            .from('sections')
            .update({
                floor_id: sectionData.floor_id,
                venue_id: sectionData.venue_id,
                styles: sectionData.styles,
                x_coordinate: sectionData.x_coordinate,
                y_coordinate: sectionData.y_coordinate
            })
            .eq('id', sectionData.id)
            .select()


        if (sectionError) {
            captureException(sectionError)
            console.error('Error upserting section:', sectionError)
            return
        }
        return { sectionResponse, sectionError }
    }
    const bulkUpdateSections = async (sectionData: SectionT[]) => {


        // Upsert section
        let { data: sectionResponse, error: sectionError } = await supabaseClient
            .from('sections')
            .upsert([...sectionData])
            .select(`id,
                venue_id,
            floor_id,
            styles,
            x_coordinate,
            y_coordinate,
        sections_localization(*)`)


        if (sectionError) {
            captureException(sectionError)
            console.error('Error upserting section:', sectionError)
            return
        }
        return { sectionResponse, sectionError }
    }

    const deleteSection = async (sectionId: string) => {
        const { error } = await supabaseClient.from('sections').delete().eq('id', sectionId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const getAllCategories = async (locale: string) => {
        const { data, error } = await supabaseClient.from('categories').select(`
       *,
        categories_localization (
          label,
          description,
          language_code,
          category_id
        )
      `)

        if (error) {
            captureException(error)
        }

        if (data) {
            const flattenedData = data.map((category) => {
                let localization: LocalizationT = category.categories_localization.find(
                    (loc) => loc.language_code === locale
                )
                // If not found, use the English localization as a fallback.
                if (!localization) {
                    localization = category.categories_localization.find(
                        (loc) => loc.language_code === 'en'
                    ) || {
                        label: '',
                        description: '',
                        category_id: '',
                        instruction: '',
                        language_code: '',
                        name: '',
                    }
                }

                return {
                    ...category,
                    label: localization.label,
                    description: localization.description,
                }
            })

            return { data: flattenedData, error }
        }

        return { data, error }
    }

    const createOrUpdateCategory = async (data: ModifiedExtendedCategoryT, mode: string) => {
        const { categories_localization, ...restOfData } = data

        // If in EDIT mode, include the category ID for the upsert operation
        if (mode === MODE_CONSTANTS.EDIT) {
            restOfData.id = data.id
        }
        console.log(data);

        let localizationsToUpsert = Object.entries(categories_localization).map(
            ([langCode, localization]) => ({
                language_code: langCode,
                label: localization.label,
                // Add more fields if necessary
            })
        )

        let { data: categoryData, error: categoryError } = await supabaseClient.rpc(
            'create_or_update_category',
            {
                _rest_of_data: restOfData,
                _localizations_to_upsert: localizationsToUpsert,
            }
        )

        if (categoryError) {
            captureException(categoryError)
            console.error('Error in create_or_update_category function:', categoryError)
            return [null, categoryError]
        }

        return [categoryData, null]
    }

    const deleteCategory = async (categoryId: string) => {
        const { error } = await supabaseClient.from('categories').delete().eq('id', categoryId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const uploadImage = async (url: string, file: Blob, bucketName = 'public') => {
        const { error } = await supabaseClient.storage.from(bucketName).upload(url, file, {
            cacheControl: '3600',
            upsert: true,
        })

        if (error) {
            captureException(error)
        }

        return error
    }

    const downloadImage = async (url: string, bucketName = 'public') => {
        const { data, error } = await supabaseClient.storage.from(bucketName).download(url)

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const updateImage = async (url: string, file: Blob, bucketName = 'public') => {
        const { data, error } = await supabaseClient.storage.from(bucketName).update(url, file, {
            cacheControl: '3600',
            upsert: true,
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const handleImageUploadOrUpdate = async ({
        imageFile,
        mode,
        image_url,
        tempFileUrl,
        bucket,
    }: {
        imageFile: File
        mode: string
        image_url: string
        tempFileUrl: string
        bucket: string
    }) => {
        let imageUrl: string

        // Conditions for when we need to upload or update the image.
        // Fixed condition with proper parentheses for clarity and correctness
        const shouldUpload = mode === MODE_CONSTANTS.CREATE && imageFile && !image_url
        const shouldUpdate =
            (mode === MODE_CONSTANTS.EDIT || mode === MODE_CONSTANTS.DUPLICATE) &&
            imageFile &&
            image_url !== tempFileUrl

        if (shouldUpload || shouldUpdate) {
            const error = await uploadImage(imageFile.name, imageFile, bucket)

            if (error) {
                captureException(error)
            }

            imageUrl = getNewImageUrl(imageFile.name, bucket)
        } else {
            imageUrl = image_url
        }

        return imageUrl
    }

    const createOrUpdateMenuItem = async (
        data: ModifiedExtendedMenuItemT,
        nutritionData: MenuItemNutritionT,
        selectedCategories: MenuItemsGroupCategoriesT[],
        initialMenuItemState: ModifiedExtendedMenuItemT,
        optionalGroups: ModifiedMappedGroupTWithMappedLocale[],
        mode: string
    ) => {
        const {
            menu_items_group_categories,
            menu_item_nutrition,
            menu_items_localization,
            menu_item_optional_groups,
            id,
            ...restOfData
        } = data

        let itemData: Json

        if (mode === MODE_CONSTANTS.CREATE || mode === MODE_CONSTANTS.DUPLICATE) {
            itemData = { ...restOfData }
        } else {
            itemData = { ...restOfData, id: id }
        }

        let categoriesToUpsert: { category_id: string; is_active: boolean }[] = []
        let categoriesToDelete: string[] = []
        let optionGroupsToUpsert = []
        let optionGroupsToDelete: string[] = []
        let localizationsToUpsert = []

        if (mode === MODE_CONSTANTS.CREATE || mode === MODE_CONSTANTS.DUPLICATE) {
            // For CREATE or DUPLICATE mode, treat all categories, localizations, and optionalGroups as new
            categoriesToUpsert = selectedCategories.map((category) => ({
                category_id: category.category_id,
                is_active: true,
            }))
            localizationsToUpsert = Object.entries(menu_items_localization).map(
                ([langCode, localization]) => ({
                    language_code: langCode,
                    name: localization.name,
                    description: localization.description,
                })
            )
            optionGroupsToUpsert = optionalGroups.map((group) => ({
                option_group_id: group.optionGroupId || group.id,
            }))
        } else if (mode === MODE_CONSTANTS.EDIT) {
            // Existing logic for EDIT mode
            const existingCategoryIds = initialMenuItemState.menu_items_group_categories.map(
                (category) => category.category_id
            )

            const newCategoryIds = selectedCategories.map((category) => category.category_id)

            categoriesToDelete = existingCategoryIds.filter((id) => !newCategoryIds.includes(id))
            const categoriesToInsert = newCategoryIds.filter(
                (id) => !existingCategoryIds.includes(id)
            )

            categoriesToUpsert = selectedCategories
                .filter((category) => categoriesToInsert.includes(category.category_id))
                .map((category) => ({
                    category_id: category.category_id,
                    is_active: true,
                }))

            localizationsToUpsert = Object.entries(menu_items_localization).map(
                ([langCode, localization]) => ({
                    language_code: langCode,
                    name: localization.name,
                    description: localization.description,
                })
            )

            const existingOptionGroupIds = menu_item_optional_groups.map(
                (group) => group.option_group_id || group.id
            )
            const newOptionGroupIds = optionalGroups.map((group) => group.optionGroupId || group.id)

            optionGroupsToDelete = existingOptionGroupIds.filter(
                (id) => !newOptionGroupIds.includes(id)
            )
            const optionGroupsToInsert = newOptionGroupIds.filter(
                (id) => !existingOptionGroupIds.includes(id)
            )

            optionGroupsToUpsert = optionalGroups
                .filter((group) => optionGroupsToInsert.includes(group.optionGroupId || group.id))
                .map((group) => ({
                    option_group_id: group.optionGroupId || group.id,
                }))
        }

        let { data: menuItemData, error: menuItemError } = await supabaseClient.rpc(
            'create_or_update_menu_item',
            {
                _rest_of_data: itemData,
                _nutrition_data: nutritionData,
                _categories_to_delete: categoriesToDelete,
                _categories_to_upsert: categoriesToUpsert,
                _localizations_to_upsert: localizationsToUpsert,
                _option_groups_to_delete: optionGroupsToDelete,
                _option_groups_to_upsert: optionGroupsToUpsert,
            }
        )

        if (menuItemError) {
            captureException(menuItemError)
            console.error('Error in create_or_update_menu_item function:', menuItemError)
            return { menuItemData: null, menuItemError }
        }

        return { menuItemData, menuItemError }
    }

    const getAllMenuItems = async (
        categories: ExtendedCategoryT[],
        offset = 0,
        limit = 10,
        locale = 'en'
    ) => {
        const { data, error } = await supabaseClient
            .from('menu_items')
            .select(
                `*,
                    menu_items_localization (
                    *
                    ),
                    menu_item_nutrition (
                    *
                    ),
                    menu_items_group_categories (
                    *
                    ),
                    menu_item_optional_groups:menu_item_option_groups (
                        *
                    )
                `
            )
            .range(offset, offset + limit - 1)

        if (data) {
            const flattenedData = flattenMenuItemData(data, locale, categories)

            return { menuItemsData: flattenedData, menuItemsError: null }
        }

        if (error) {
            captureException(error)
        }

        return { menuItemsData: null, menuItemsError: error }
    }

    const deleteMenuItem = async (id: string) => {
        const { error } = await supabaseClient.from('menu_items').delete().eq('id', id)

        if (error) {
            captureException(error)
        }

        return error
    }

    const searchMenuItemsStaff = async ({
        search_value = '',
        apply_category_active_condition = false,
        apply_item_active_condition = false,
        apply_searchable_condition = true,
        include_group_categories = false,
        include_localization = false,
        include_nutrition = false,
        include_optional_groups = false,
        language_code = 'en',
    }) => {
        const { data, error } = await supabaseClient.rpc('staff_search_menu_items', {
            apply_category_active_condition,
            apply_item_active_condition,
            apply_searchable_condition,
            include_group_categories,
            include_localization,
            include_nutrition,
            include_optional_groups,
            language_code,
            search_value,
        })

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const getAllOptionGroups = async (offset = 0, limit = 10) => {
        const { data, error } = await supabaseClient
            .from('option_groups')
            .select(
                `
        *,
        option_groups_localization (
           *
        ),
        optional_menu_items_group (*),
        option_group_items: menu_items (
            *,
            option_items_localization: menu_items_localization (
               *
            )
        )
    `
            )
            .range(offset, offset + limit - 1)
            .order('id')

        if (error) {
            captureException(error)
        }

        return { optionalGroups: data, groupsError: error }
    }

    const getExistingMenuItemOptionGroups = async (menu_item_id: string) => {
        const { data, error } = await supabaseClient
            .from('menu_item_option_groups')
            .select(
                `
      *,
      option_groups (
        *,
        option_groups_localization: option_groups_localization (
          *
        ),
        option_group_items: menu_items (
          *,
          option_items_localization: menu_items_localization (
            *
          )
        )
      )
    `
            )
            .eq('menu_item_id', menu_item_id)

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const searchOptionalGroups = async ({
        search_value,
        p_language_code,
        include_localization = true,
        include_menu_items = true,
    }) => {
        let { data, error } = await supabaseClient.rpc('search_option_groups', {
            include_localization,
            include_menu_items,
            p_language_code,
            search_value,
        })

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const createOrUpdateOptionalGroup = async (
        data: Partial<ModifiedMappedGroupTWithMappedLocale>,
        selectedOptions: ModifiedExtendedOptionalItemT[],
        initialOptionalGroupState: ModifiedMappedGroupTWithMappedLocale,
        mode: ENUM_MODES
    ) => {
        const { option_groups_localization, option_group_items, ...restOfData } = data

        let localizationsToUpsert = Object.entries(option_groups_localization).map(
            ([langCode, localization]) => ({
                language_code: langCode,
                name: localization.name,
                instruction: localization.instruction,
            })
        )

        let optionItemsToDelete = []
        let optionItemsToUpsert = []

        if (mode === MODE_CONSTANTS.CREATE || mode === MODE_CONSTANTS.DUPLICATE) {
            // For CREATE or DUPLICATE mode, treat all options as new
            optionItemsToUpsert = selectedOptions.map((option) => ({
                optional_item_id: option.id,
            }))
        } else if (mode === MODE_CONSTANTS.EDIT) {
            // Existing logic for EDIT mode
            const existingOptionIds = initialOptionalGroupState.option_group_items.map(
                (option) => option.option_id || option.id
            )

            const newOptionIds = selectedOptions.map((option) => option.id)

            optionItemsToDelete = existingOptionIds.filter((id) => !newOptionIds.includes(id))
            const optionItemsToInsert = newOptionIds.filter((id) => !existingOptionIds.includes(id))

            optionItemsToUpsert = selectedOptions
                .filter((option) => optionItemsToInsert.includes(option.id))
                .map((option) => ({
                    optional_item_id: option.id,
                }))
        }

        let { data: optionalGroupData, error: optionalGroupError } = await supabaseClient.rpc(
            'create_or_update_optional_group',
            {
                _rest_of_data: restOfData,
                _localizations_to_upsert: localizationsToUpsert,
                _option_items_to_delete: optionItemsToDelete,
                _option_items_to_upsert: optionItemsToUpsert,
            }
        )

        if (optionalGroupError) {
            captureException(optionalGroupError)
            console.error('Error in create_or_update_optional_group function:', optionalGroupError)
            return { optionalGroupData, optionalGroupError }
        }

        // Additional logic to handle the returned data from the function
        // ...

        return { optionalGroupData, optionalGroupError }
    }

    const deleteOptionGroup = async (id: string) => {
        const { error } = await supabaseClient.from('option_groups').delete().eq('id', id)

        if (error) {
            captureException(error)
        }

        return error
    }

    const createOrUpdateMenu = async (
        data: ExtendedMenusT,
        selectedCategories: MenuCategoryT[],
        mode: string
    ) => {
        const { created_at, updated_at, menu_categories, label, ...restOfData } = data

        let categoriesToDelete: string[] = []

        let categoriesToUpsert = selectedCategories.map((category) => ({
            category_id: category.id,
            order_number: category.order_number,
        }))

        if (mode === MODE_CONSTANTS.EDIT) {
            // Existing logic for EDIT mode
            const existingCategoryIds: string[] = menu_categories.map(
                (category) => category.category_id || category.id
            )
            const newCategoryIds: string[] = selectedCategories.map((category) => category.id)

            categoriesToDelete = existingCategoryIds.filter((id) => !newCategoryIds.includes(id))
        }

        let { data: menuData, error: menuError } = await supabaseClient.rpc(
            'create_or_update_menu',
            {
                _rest_of_data: restOfData,
                _categories_to_delete: categoriesToDelete,
                _categories_to_upsert: categoriesToUpsert,
            }
        )

        if (menuError) {
            captureException(menuError)
            console.error('Error in create_or_update_menu function:', menuError)
            return [null, menuError]
        }

        return [menuData, null]
    }

    const getMenus = async () => {
        const { error, data } = await supabaseClient.from('menus').select(`
        *,
        menu_categories (
            *
        )
      `)

        if (error) {
            captureException(error)
        }

        return { menuData: data, menuError: error }
    }

    const deleteMenu = async (menuId: string) => {
        const { error } = await supabaseClient.from('menus').delete().eq('id', menuId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const getEvents = async () => {
        const { data, error } = await supabaseClient.from('events').select(`
          *,
          events_localization (
              *
          ),
          event_menus(
              *,
              menus(
                  *
              )
          )
      `)

        if (error) {
            captureException(error)
            console.error('Error fetching events:', error)
        }

        return { eventsData: data, eventError: error }
    }

    const createOrUpdateEvent = async (
        eventData: ModifiedMappedExtendedEventsT,
        eventLocalizations: TMappedLocalization,
        selectedMenus: ExtendedMenusT[],
        mode: string
    ) => {
        const { events_localization, event_menus, description, name, ...restOfData } = eventData

        // Prepare localizations for upsert
        let localizationsToUpsert = Object.entries(eventLocalizations).map(
            ([langCode, localization]) => ({
                language_code: langCode,
                name: localization.name,
                description: localization.description,
            })
        )

        let menusToDelete: string[] = []
        let menusToUpsert: { menu_id: string }[] = []

        if (mode === MODE_CONSTANTS.CREATE || mode === MODE_CONSTANTS.DUPLICATE) {
            // For CREATE or DUPLICATE mode, treat all menus as new
            menusToUpsert = selectedMenus.map((menu) => ({
                menu_id: menu.menu_id || menu.id,
            }))
        } else if (mode === MODE_CONSTANTS.EDIT) {
            // Existing logic for EDIT mode
            const existingMenuIds = event_menus.map((menu) => menu.menu_id)
            const newMenuIds = selectedMenus.map((menu) => menu.menu_id || menu.id)

            menusToDelete = existingMenuIds.filter((id) => !newMenuIds.includes(id))
            const menusToInsert = newMenuIds.filter((id) => !existingMenuIds.includes(id))

            menusToUpsert = selectedMenus
                .filter((menu) => menusToInsert.includes(menu.menu_id || menu.id))
                .map((menu) => ({
                    menu_id: menu.menu_id || menu.id,
                }))
        }

        // Call the SQL function
        let { data: eventDataResult, error: eventError } = await supabaseClient.rpc(
            'create_or_update_event',
            {
                _rest_of_data: restOfData,
                _localizations_to_upsert: localizationsToUpsert,
                _menus_to_delete: menusToDelete,
                _menus_to_upsert: menusToUpsert,
            }
        )

        if (eventError) {
            captureException(eventError)
            console.error('Error in create_or_update_event function:', eventError)
            return [null, eventError]
        }

        return [eventDataResult, null]
    }

    const deleteEvent = async (eventId: string) => {
        const { error } = await supabaseClient.from('events').delete().eq('id', eventId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const createOrUpdateVenue = async (
        data: ExtendedVenueSettingsT,
        workingHours: MappedWorkingHoursT
    ) => {
        const {
            id,
            active_working_hours,
            working_hours,
            days_of_operation,
            starting_hours,
            ending_hours,
            ...restOfData
        } = data

        // Upsert main venue data
        let { error: venueError } = await supabaseClient
            .from('venue_settings')
            .upsert([{ ...restOfData, id }], { onConflict: 'id' })

            .single()

        if (venueError) {
            captureException(venueError)
            console.error('Error upserting venue:', venueError)
            return [null, venueError]
        }

        // Handle working hours
        const workingHoursToUpsert = Object.entries(workingHours).map(([dayOfWeek, hoursData]) => ({
            day_of_week: Number(dayOfWeek),
            starting_hours: hoursData.starting_hours,
            ending_hours: hoursData.ending_hours,
            is_active: hoursData.is_active,
            venue_id: id
        }))

        const { data: workingHoursData, error: workingHoursError } = await supabaseClient
            .from('working_hours')
            .upsert(workingHoursToUpsert, { onConflict: 'day_of_week,venue_id' })
            .select()

        if (workingHoursError) {
            captureException(workingHoursError)
            console.error('Error upserting working hours:', workingHoursError)
            return [null, workingHoursError]
        }

        return [
            {
                ...restOfData,
                workingHoursData,
            },
            null,
        ]
    }

    const searchOrdersHistory = async (
        column: string,
        value: string,
        pageIndex: number,
        pageSize: number,
        fromDate: string,
        toDate: string
    ) => {
        const { data, error } = await supabaseClient.rpc('search_orders_history', {
            _column_name: column,
            _search_value: value,
            _from_date: fromDate,
            _to_date: toDate,
            _page_index: pageIndex,
            _page_size: pageSize,
        })

        if (error) {
            captureException(error)
            return { orders: data, error, count: 0 }
        }

        return { orders: data[0]?.data ? data[0]?.data : [], error, count: data[0]?.count }
    }

    const searchCurrentOrders = async (
        column: string,
        value: string,
        pageIndex: number,
        pageSize: number,
        fromDate: string,
        toDate: string
    ) => {
        const { data, error } = await supabaseClient.rpc('search_current_orders', {
            _column_name: column,
            _search_value: value,
            _from_date: fromDate,
            _to_date: toDate,
            _page_index: pageIndex,
            _page_size: pageSize,
        })

        if (error) {
            captureException(error)
            return { orders: data, error, count: 0 }
        }

        return { orders: data[0]?.data ? data[0]?.data : [], error, count: data[0]?.count }
    }

    const getOrdersHistory = async (
        fromDate: string,
        toDate: string,
        pageIndex = 0,
        pageSize = 20
    ) => {
        const offset = pageIndex * pageSize
        let {
            data: orders,
            error,
            count,
        } = await supabaseClient
            .from('orders_history')
            .select('*', { count: 'exact' })
            .gte('created_at', fromDate)
            .lte('created_at', toDate)
            .range(offset, offset + pageSize - 1)
            .order('created_at', { ascending: false })

        if (error) {
            captureException(error)
        }

        return { orders, error, count }
    }
    const getCurrentOrders = async (pageIndex = 0, pageSize = 20) => {
        const offset = pageIndex * pageSize
        let {
            data: orders,
            error,
            count,
        } = await supabaseClient
            .from('orders')
            .select('*', { count: 'exact' }) // get the exact count of rows
            .range(offset, offset + pageSize - 1)
            .order('created_at', { ascending: false })

        if (error) {
            captureException(error)
        }

        return { orders, error, count }
    }

    const getHistoryOrdersByReceiptId = async (receiptId: string) => {
        let { data: orders, error } = await supabaseClient
            .from('orders_history')
            .select('*') // You can specify only the columns you need
            .eq('receipt_id', receiptId)

        if (error) {
            captureException(error)
        }

        return [orders, error]
    }
    const getOrdersByReceiptId = async (receiptId: string) => {
        let { data: orders, error } = await supabaseClient
            .from('orders')
            .select('*') // You can specify only the columns you need
            .eq('receipt_id', receiptId)

        if (error) {
            captureException(error)
        }

        return [orders, error]
    }

    const fetchOrderFromHistory = async (orderId: string) => {
        const { data, error } = await supabaseClient.rpc('fetch_order_with_details_from_history', {
            _order_id: orderId,
        })

        if (error) {
            captureException(error)
            console.error('Error fetching order with details from history:', error)
            return { data, error }
        }

        return { data, error }
    }

    const getMultipleMenuItemsLocaleFromHistory = async (ids: string[], locale: string) => {
        const { data, error } = await supabaseClient
            .from('menu_items_localization_history')
            .select('*')
            .in('language_code', [locale, 'en'])
            .in('item_id', ids)

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const getOrderHistorySummaryData = async (
        startDate: string,
        endDate: string,
        includeGrowth: boolean,
        comparisonType: string
    ) => {
        const { data, error } = await supabaseClient.rpc('aggregate_order_history_summary', {
            interval_start: startDate,
            interval_end: endDate,
            show_growth: includeGrowth,
            comparison_type: comparisonType,
        })

        if (error) {
            captureException(error)
            console.error('Error fetching order with details from history:', error)
            return [null, error]
        }

        return [data, null]
    }
    const getCurrentOrderSummaryData = async (
        startDate: string,
        endDate: string,
        includeGrowth: boolean,
        comparisonType: string
    ) => {
        const { data, error } = await supabaseClient.rpc('aggregate_current_order_summary', {
            interval_start: startDate,
            interval_end: endDate,
            show_growth: includeGrowth,
            comparison_type: comparisonType,
        })

        if (error) {
            captureException(error)
            console.error('Error fetching order with details from history:', error)
            return [null, error]
        }

        return [data, null]
    }

    // Memoize the returned object
    const objectApi = {
        getUserProfiles,
        deleteUserProfile,
        createUserProfile,
        updateUserProfile,
        getFloorsLayout,
        createOrUpdateFloor,
        deleteFloor,
        getSectionsLayout,
        createOrUpdateSection,
        updateSection,
        bulkUpdateSections,
        deleteSection,
        getDefaultTablesLayout,
        deleteDefaultTable,
        updateDefaultTable,
        bulkUpdateDefaultTable,
        createDefaultTable,
        getAllCategories,
        handleImageUploadOrUpdate,
        downloadImage,
        uploadImage,
        updateImage,
        createOrUpdateCategory,
        deleteCategory,
        getAllMenuItems,
        createOrUpdateMenuItem,
        deleteMenuItem,
        searchMenuItemsStaff,
        getAllOptionGroups,
        getExistingMenuItemOptionGroups,
        searchOptionalGroups,
        createOrUpdateOptionalGroup,
        deleteOptionGroup,
        createOrUpdateMenu,
        getMenus,
        deleteMenu,
        getEvents,
        createOrUpdateEvent,
        deleteEvent,
        createOrUpdateVenue,
        searchCurrentOrders,
        searchOrdersHistory,
        getOrdersHistory,
        getCurrentOrders,
        fetchOrderFromHistory,
        getMultipleMenuItemsLocaleFromHistory,
        getHistoryOrdersByReceiptId,
        getOrdersByReceiptId,
        getOrderHistorySummaryData,
        getCurrentOrderSummaryData,
    }

    return objectApi
}

export default useAdminPanelServiceApi
