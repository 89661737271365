import { ORDER_STATUS_CONSTANTS } from '@/constants/constants.ts'

import { ENUM_ACTION_TYPES } from '@/enums/Enums'

import { updateTables } from '@/utils/functionUtils'
import {
    getDefaultExtendedReservationStructure,
    getDefaultTableStructure,
} from '@/utils/stateUtils'

import {
    ApplicationAction,
    ExtendedReservationT,
    ExtendedTableT,
    ReservationState,
} from '@/types/globalTypes'

const reservationsReducer = (
    prevState: ReservationState,
    action: ApplicationAction
): ReservationState => {
    switch (action.type) {
        case ENUM_ACTION_TYPES.INSERT_RESERVATION: {
            const { newReservation } = action.payload
            const reservationIndex = prevState.reservations.findIndex(
                (reservation) => reservation.id === newReservation.id
            )
            if (reservationIndex !== -1) {
                return prevState
            }
            let updatedReservations = [...prevState.reservations]

            if (newReservation.table_id !== '' && newReservation.table_id != null) {
                const newReservationTable = prevState.tablesData.find(
                    (table) => table.id === newReservation.table_id
                )

                if (newReservationTable) {
                    updatedReservations = [
                        ...updatedReservations,
                        { ...newReservation, table: newReservationTable },
                    ]
                } else {
                    // Fallback or default table structure
                    updatedReservations = [
                        ...updatedReservations,
                        { ...newReservation, table: getDefaultTableStructure() },
                    ]
                }
            } else {
                // Directly use newReservation.table if it's already of type TableT or null
                updatedReservations = [
                    ...updatedReservations,
                    { ...newReservation, table: getDefaultTableStructure() },
                ]
            }

            let updatedTables = [...prevState.tablesData]

            if (newReservation.table_id && newReservation.table_id !== '') {
                updatedTables = updatedTables.map((table) => {
                    if (table.id === newReservation.table_id) {
                        return { ...table, reservation: newReservation }
                    }
                    return table
                }) as ExtendedTableT[]
            }

            return { ...prevState, reservations: updatedReservations, tablesData: updatedTables }
        }

        case ENUM_ACTION_TYPES.UPDATE_RESERVATION: {
            const { updatedReservation } = action.payload

            const reservationIndex = prevState.reservations.findIndex(
                (reservation) => reservation.id === updatedReservation.id
            )

            const updatedReservations = [...prevState.reservations]

            const ordersForThisReservation = prevState.orders.filter(
                (order) => order.reservation_id === updatedReservation.id
            )

            const hasPendingOrder = ordersForThisReservation.some(
                (order) => order.status === ORDER_STATUS_CONSTANTS.PENDING
            )

            let mappedReservationData: ExtendedReservationT

            if (
                (updatedReservation.table_id !== '' && updatedReservation.table_id != null) ||
                updatedReservation.is_static
            ) {
                const newTable = prevState.tablesData.find(
                    (table) =>
                        table.id === updatedReservation.table_id ||
                        table.static_reservation_id === updatedReservation.id
                )
                mappedReservationData = { pin_code: updatedReservations[reservationIndex].pin_code, ...updatedReservation, hasPendingOrder, table: newTable }

                updatedReservations[reservationIndex] = mappedReservationData
            } else {

                mappedReservationData = {
                    pin_code: updatedReservations[reservationIndex].pin_code,
                    ...updatedReservation,
                    hasPendingOrder,
                    table: getDefaultTableStructure(),
                }
                updatedReservations[reservationIndex] = mappedReservationData
            }

            const updatedTables = updateTables(
                prevState.tablesData,
                prevState.reservations,
                mappedReservationData,
                hasPendingOrder
            )

            return { ...prevState, reservations: updatedReservations, tablesData: updatedTables }
        }

        case ENUM_ACTION_TYPES.UPDATE_RESERVATIONS: {
            const { updatedReservations } = action.payload

            let newReservations = [...prevState.reservations]
            let newTables = [...prevState.tablesData]

            updatedReservations.forEach((updatedReservation) => {
                const reservationIndex = newReservations.findIndex(
                    (reservation) => reservation.id === updatedReservation.id
                )

                const ordersForThisReservation = prevState.orders.filter(
                    (order) => order.reservation_id === updatedReservation.id
                )

                const hasPendingOrder = ordersForThisReservation.some(
                    (order) => order.status === ORDER_STATUS_CONSTANTS.PENDING
                )

                let mappedReservationData: ExtendedReservationT

                if (
                    (updatedReservation.table_id !== '' && updatedReservation.table_id != null) ||
                    updatedReservation.is_static
                ) {
                    const newTable = newTables.find(
                        (table) =>
                            table.id === updatedReservation.table_id ||
                            table.static_reservation_id === updatedReservation.id
                    )
                    mappedReservationData = { pin_code: newReservations[reservationIndex].pin_code, ...updatedReservation, hasPendingOrder, table: newTable }

                    newReservations[reservationIndex] = mappedReservationData
                } else {

                    mappedReservationData = {
                        pin_code: newReservations[reservationIndex].pin_code,
                        ...updatedReservation,
                        hasPendingOrder,
                        table: getDefaultTableStructure(),
                    }
                    newReservations[reservationIndex] = mappedReservationData
                }

                newTables = updateTables(
                    newTables,
                    newReservations,
                    mappedReservationData,
                    hasPendingOrder
                )
            })

            return { ...prevState, reservations: newReservations, tablesData: newTables }
        }

        case ENUM_ACTION_TYPES.DELETE_RESERVATION: {
            const { deletedReservationId } = action.payload

            // Remove the reservation from the reservations array
            const updatedReservations = prevState.reservations.filter(
                (reservation) => reservation.id !== deletedReservationId
            )

            // Update the associated table to remove the reservation
            const updatedTables = prevState.tablesData.map((table) => {
                if (table.reservation && table.reservation.id === deletedReservationId) {
                    return {
                        ...table,
                        reservation: getDefaultExtendedReservationStructure(),
                    }
                }
                return table
            }) as ExtendedTableT[]

            return { ...prevState, reservations: updatedReservations, tablesData: updatedTables }
        }

        case ENUM_ACTION_TYPES.DELETE_RESERVATIONS: {
            const { ids } = action.payload

            // Filter out deleted reservations
            const updatedReservations = prevState.reservations.filter(
                (reservation) => !ids.includes(reservation.id)
            )

            // Update associated tables to clear deleted reservations
            const updatedTables = prevState.tablesData.map((table) => {
                if (ids.includes(table.reservation?.id)) {
                    return {
                        ...table,
                    }
                }
                return table
            })

            return { ...prevState, reservations: updatedReservations, tablesData: updatedTables }
        }

        // Handle other action types...

        default:
            return prevState
    }
}

export default reservationsReducer
